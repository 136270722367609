<template>
  <div id="history-data">
    <!-- 历史数据 -->
    <app-tree @select="onTreeSelect" @clickExpand="onClickExpand"></app-tree>
    <div class="content">
      <!-- 顶部标题 -->
      <!-- <div class="title-wrapper">
                {{ $store.state.parentData.name }}-{{ $store.state.selectedData.name }}
            </div> -->
      <div class="title-wrapper">
        <span v-if="parentTitle">{{ parentTitle }}</span
        >-<span v-if="title">{{ title }}</span>
      </div>

      <!-- 筛选条件 -->
      <div class="search">
        <a-row>
          <a-col :span="8">
            数据类型：
            <a-select
              :style="inputStyle"
              v-model="TJLX"
              name="TJLX"
              @change="onChange"
              placeholder="请选择数据类型"
            >
              <a-select-option value="4"> 月数据 </a-select-option>
              <a-select-option value="2"> 日数据 </a-select-option>
              <a-select-option value="1"> 小时数据 </a-select-option>
              <a-select-option value="5"> 分钟数据 </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="16">
            时间段：
            <a-range-picker
              @change="onChooseDate"
               @panelChange="handlePanelChange2"
              :format="dateFormat"
              :mode="mode"
              style="width: 300px"
              :default-value="[
                moment($store.state.KSSJ1, dateFormat),
                moment($store.state.JSSJ, dateFormat),
              ]"
              :placeholder="['请选择开始时间', '请选择结束时间']"
            >
            </a-range-picker>
            <a-button type="primary" @click="onSearch" style="margin: 0px 10px"
              >查询</a-button
            >
            <a-button type="primary" @click="onExport"> 导出 </a-button>
          </a-col>
        </a-row>
        <a-row style="margin: 10px 0px">
          <a-col :span="8">
            显示方式：
            <a-radio-group
              name="radioGroup"
              :default-value="1"
              @change="onChamgeType"
            >
              <a-radio :value="1"> 列表 </a-radio>
              <a-radio :value="2"> 图表 </a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="10" class="area-wrapper" v-show="isShowAlarmColor">
            报警颜色：
            <div class="area" style="background-color: #800080"></div>
            异常
            <div class="area" style="background-color: #ff0000"></div>
            超标
            <div class="area" style="background-color: #ff8000"></div>
            预警
          </a-col>
          <!--数据方式显示条件station_Type为1，是气基站；showType为2当前为图表-->
          <!-- <a-col :span="8" v-if="selectedData.station_Type==1&&showType==2">
            数据方式：
            <a-radio-group
              name="radioGroup"
              :default-value="1"
              @change="onDateType"
            >
              <a-radio :value="1"> 实测 </a-radio>
              <a-radio :value="2"> 折算 </a-radio>
            </a-radio-group>
          </a-col> -->
          <a-col :span="6"
            ><div v-show="currentStationIsTingYun">
              状态：
              <a-dropdown class="statusMenu">
                <a @click="(e) => e.preventDefault()"
                  ><span style="color: red; cursor: pointer"
                    >停运(时段)</span
                  ></a
                >
                <a-menu slot="overlay" class="statusMenu">
                  <a-menu-item
                    class="statusMenu"
                    style="background: #fff !important; padding: 15px"
                  >
                    <a-table
                      :columns="columnsSd"
                      :data-source="dataSd"
                      :pagination="false"
                      bordered
                      :row-key="
                        (r, i) => {
                          return i.toString();
                        }
                      "
                      size="small"
                      class="timetable"
                      :loading="loadingSd"
                    >
                    </a-table>
                    <div
                      v-show="totalSd > 10"
                      style="
                        display: flex;
                        padding-bottom: 10px;
                        margin-top: 10px;
                      "
                    >
                      <div style="flex: 1"></div>
                      <a-pagination
                        show-quick-jumper
                        :page-size="pageSizeSd"
                        v-model="currentSd"
                        :total="totalSd"
                        @change="onChangeSdPage"
                      />
                    </div>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-col>
        </a-row>
        <h3 style="font-weight: bold; text-align: center">
          {{ smallTitle }}
        </h3>
      </div>
      <!-- echarts -->
      <div v-show="showType == 2" :style="{ zIndex: zEchartsIndex }">
        <div
          class="echart"
          id="EchartsHistory"
          :style="{ width: echartWidth }"
        ></div>
      </div>
      <!--echarts 是气基站的时候显示折算图表--->
      <div v-show="selectedData.station_Type==1&&showType == 2" :style="{ zIndex: zEchartsIndex }">
         <h3 style="font-weight: bold; text-align: center;margin-top:10px;">
          {{ smallzsTitle }}
        </h3>
          <div
            class="echart"
            id="EchartsHistoryZS"
            :style="{ width: echartWidth }"
          ></div>
      </div>
      <!-- 内容显示区 -->
      <div v-show="showType == 1" :style="{ zIndex: zTableIndex }">
        <!-- table展示 -->
        <!-- table表格区域 -->
        <a-table
          class="table-wrapper"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          bordered
          :row-key="
            (r, i) => {
              return i.toString();
            }
          "
          size="small"
          :loading="loading"
          :scroll="tableScroll"
        >
          <!--为了测试弹出框原来的代码先注释20220118-->
          <!-- <span slot="filterfff" slot-scope="text,record" v-html="formatTable(text,record)" v-if="TJLX[0]!=1">
          </span> -->
          <template slot="filterfff" slot-scope="text, record">
            <div class="mypopconfirm">
              <!-- <span v-html="formatTable(text,record)" v-if="TJLX[0]!=1"></span> -->
              <span v-if="isHasAlarm(text,record)" style="display:flex;justify-content:center;">
                <a-popover placement="right" trigger="click" @visibleChange="onVisibleChange($event,text,record)">
                    <template slot="content" class="myalarm" v-if="isHasAlarmInfo">
                      <div style="padding:12px;">
                        <div style="display:flex;width:396px;">
                          <div style="width:71px;">报警信息：</div>
                          <div style="flex:1">{{alarminfo.AlarmMsg}}</div>
                        </div>
                        <div class="deal-time" v-if="isDealAlarm(text,record)"><span style="padding-left:15px;">处理人：</span>{{alarminfo.CLRY}}</div>
                        <div class="deal-time">报警时间：{{alarminfo.BJSJ}}</div>
                        <div class="alarmdeal" v-if="LX!=1&&alarminfo.SFCL==0" @click="onDeal(alarminfo.BJLX,text)">立即处理</div>
                        <div class="deal-time" v-if="isDealAlarm(text,record)">处理说明：{{alarminfo.Explain}}</div>
                      </div>
                    </template>
                    <div style="display:flex;align-items:center;height:20px;">
                        <span @click.stop="" v-html="formatTable(text,record)"></span>
                        <!--有报警已处理-->
                        <img class="alarm" v-if="DealAlarmStatus(text,record)==2" :src="require('@/assets/img/pics/alarm.png')" />
                        <!--有报警未处理-->
                        <img class="alarm" v-if="DealAlarmStatus(text,record)==1" :src="require('@/assets/img/pics/alarmred.png')" />
                         <!--有报警已处理 历史报警-->
                        <img class="alarm" v-if="DealAlarmStatus(text,record)==3" :src="require('@/assets/img/pics/alarmh.png')" />
                    </div>
                  </a-popover>
              </span>
              <span v-html="formatTable(text,record)" v-else></span>
            </div>
          </template>
        </a-table>
        <!-- 分页器区域 -->
        <!-- <a-pagination
                    class="pagination-wrapper"
                    :default-current="start"
                    :total="alltotal"
                    @change="onPageChange"
                    @showSizeChange="onShowSizeChange"
                /> -->
        <!--停运时段的对话框-->
        <a-modal :footer="null" v-model="modal1Visible" centered> </a-modal>
      </div>
    </div>
    <a-modal
    title="报警处理"
    :visible="alarmVisible"
    :confirm-loading="confirmLoading"
    @ok="handleAlarmOk"
    @cancel="handleAlarmCancel"
    centered
    class="myalarmmodel"
    :width="400"
    :zIndex=999
    >
    <div v-if="alarminfo.BJLX==1">报警类型：异常</div>
    <div v-if="alarminfo.BJLX==2">报警类型：超标</div>
    <div v-if="alarminfo.BJLX==3">报警类型：预警</div>
    <div class="dealinfo" style="width:352px;">
      <div style="width:71px;">报警信息：</div>
      <div style="flex:1">{{alarminfo.AlarmMsg}}</div>
    </div>
    <div>报警时间：{{alarminfo.BJSJ}}</div>
    <a-form-model
      ref="ruleForm"
      :rules="rules"
      :model="form"
    >
   <a-form-model-item label="处理说明" prop="Explain">
      <a-input style="height:150px;width:273px;" v-model="form.Explain" type="textarea" />
    </a-form-model-item>
    </a-form-model>    
    </a-modal>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import { http } from "@/utils/Http.js";
import dayjs from "dayjs";
import moment from "moment";
const columnsSd = [
  {
    dataIndex: "TYSD",
    key: "TYSD",
    width: 200,
    align: "center",
    title: "停运时段",
  },
  {
    dataIndex: "TYYY",
    key: "TYYY",
    width: 180,
    align: "center",
    title: "停运原因",
  },
];
const alarminfo={
  CLRY:'',
  BJSJ:'',
  Explain:'',
  AlarmMsg:'',
  BJLX:'',
  SFCL:0
};
export default {
  name: "history-data-page",
  data() {
    return {
      // 顶部的title
      // title: "全国",
      layout:'horizontal',
      BJLX:'',
      form: {
       Explain:''
      },
      rules:{
        Explain:[
          {required: true, message: '请输入处理说明',trigger: 'change'}
        ]
      },
      alarmVisible:false,//处理说明对话框
      confirmLoading:false,//处理说明确认正在加载
      zTableIndex: "2",
      zEchartsIndex: "1",
      currentStationIsTingYun: false, //当前基站是否停运 停运为true显示状态停运
      smallTitle: "历史小时数据",
      smallzsTitle:'历史小时折算数据',
      modal1Visible: false, //停运时段的对话框
      // 树的选择数据
      selectedData: {},

      inputStyle: { width: "150px" },
      // 筛选区域
      TJLX: ["1"], // 数据类型：1：小时数据。2：日数据。。4：月数据
      dateFormat: "YYYY-MM-DD",
      date1: this.$store.state.KSSJ1,
      date2: this.$store.state.JSSJ,

      mode: ["date", "date"],

      showType: 1, // 1:table显示。2：echarts显示。
      columnsSd: columnsSd,
      echartWidth: "800px", //图表宽度
      // table 相关
      columns: [],
      data: [],
      dataSd: [], //停运时段数据
      alltotal: 0, // 总数据的length
      start: 1, // table 页面，从第几页开始。
      limit: 20, // 一页多少条。
      startSd: 1, // 时段table 页面，从第几页开始。
      limitSd: 10, // 一页多少条。
      pageSizeSd: 10, //时段
      currentSd: 1,
      totalSd: 0,
      loading: false,
      loadingSd: false,
      slotcolumns: [], // 所有的类型，要进行颜色判断
      isShowAlarmColor: true, //是否显示报警颜色
      // echarts 图表相关
      //color: ["#2EC7C9", "#B6A2DE", "#5AB1EF"],
      // color1: ["#2EC7C920", "#B6A2DE20", "#5AB1EF20"],
      colorLegend: [
        "rgb(34, 215, 213)",
        "rgb(219, 217, 17)",
        "rgb(123, 176, 255)",
        "rgb(253, 123, 255)",
        "rgb(255, 191, 123)",
        "rgb(69, 218, 97)",
        "rgb(255, 108, 108)",
        "rgb(201, 111, 50)",
        "rgb(141, 83, 198)",
      ],
      color: [
        "rgba(34, 215, 213,0.4)",
        "rgba(219, 217, 17,0.4)",
        "rgba(123, 176, 255,0.4)",
        "rgba(253, 123, 255,0.4)",
        "rgba(255, 191, 123,0.4)",
        "rgba(69, 218, 97,0.4)",
        "rgba(255, 108, 108,0.4)",
        "rgba(201, 111, 50,0.4)",
        "rgba(141, 83, 198,0.4)",
      ],
      color1: [
        "rgba(34, 215, 213,0)",
        "rgba(219, 217, 17,0)",
        "rgba(123, 176, 255,0)",
        "rgba(253, 123, 255,0)",
        "rgba(255, 191, 123,0)",
        "rgba(69, 218, 97,0)",
        "rgba(255, 108, 108,0)",
        "rgba(201, 111, 50,0)",
        "rgba(141, 83, 198,0)",
      ],

      parentTitle: "",
      title: "",
      alarminfo:Object.assign({},alarminfo),
      isHasAlarmInfo:false,
      LX:''
    };
  },
  /**
   * 组件
   */
  components: {
    AppTree,
  },
  /**
   * 计算属性
   */
  computed: {
    tableScroll() {
      return { x: "800px", y: "calc(100vh - 380px)" };
      //return { x: "800px", y: 0 };
    },
    isHasAlarm(){
      return function(text,record){
        let arys=text.split('|||');//表示含有AlarmState AlarmId并进行了 值的累加
        //1表示有报警未处理 2表示有报警已处理 报警信息含有折算优先显示到折算里面  没有显示到实测里面  排放量不显示
        if(arys.length>2&&(Number(arys[2])==1||arys[2]==2||arys[2]==3)&&arys[4]=='ZS'){
          return true
        }
        // else if(arys.length>2&&Number(arys[2])==1||arys[2]==2&&arys[4]=='SC'){
        //   return true
        // }
        // console.log(record);
        // for(let key in record){//1表示有报警未处理 2表示有报警已处理
        //   if(key.indexOf('AlarmState')>-1&&Number(record[key])==1||Number(record[key])==2){
        //     //console.log(text);
        //    // console.log(record[key]);
        //     return true
        //   }
        // }
        return false
      }
    },
    isDealAlarm(){
      return function(text,record){
        let arys=text.split('|||');//表示含有AlarmState AlarmId并进行了 值的累加
        //2表示有报警已处理
        if(arys.length>2&&arys[2]==2){
          return true
        }
        return false
      }
    },
    DealAlarmStatus(){
      return function(text,record){
        let arys=text.split('|||');//表示含有AlarmState AlarmId并进行了 值的累加
        //2表示有报警已处理
        if(arys.length>2&&arys[2]==1){
          return 1
        }
        if(arys.length>2&&arys[2]==2){
          return 2
        }
        if(arys.length>2&&arys[2]==3){
          return 3
        }
        return 0
      }
    }
  },
  /**
   * 方法
   */
  methods: {
    moment,
    //显示停运时段
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible;
    },
    //捕捉鼠标悬浮popover事件
    onVisibleChange:function(e,text,record){
      console.log(e);
      var me=this;
      me.isHasAlarmInfo=false;
      if(e){
        //this.alarminfo=Object.assign({},alarminfo);
        let arys=text.split('|||');//表示含有AlarmState AlarmId并进行了 值的累加
        //1表示有报警未处理 2表示有报警已处理
        if(arys.length>2&&Number(arys[2])==1||Number(arys[2])==2||Number(arys[2])==3){
          http({
            url: "/data/HJJC_ALARM_DETAILS.aspx",
            data: {
                CKM: me.$store.state.loggedIn.CKM,
                AlarmID: arys[3]
            },
            success(obj) {
              me.isHasAlarmInfo=true;
              me.alarminfo=obj.Data[0];
              console.log(me.alarminfo);
            },
              failure(obj) {
                me.alarminfo=Object.assign({},alarminfo)
              },
              me,
            });
        }
      }
    },
    //onDeal 立即处理
    onDeal(BJLX,text){
      var me=this;
      var BJLX=me.alarminfo.BJLX;
      this.AlarmID='';
      let arys=text.split('|||');//表示含有AlarmState AlarmId并进行了 值的累加
      let AlarmID=arys[3];
      this.AlarmID=AlarmID;
      //报警类型为1和2
      if(BJLX==1||BJLX==2){
        this.alarmVisible=true;
      }
      if(BJLX==3){
        this.confirmDeal();
      }
    },
    //confirmDeal
    confirmDeal(){
      var me=this;
      this.$confirm({
      title: '报警处理',
      content: '是否确认当前预警',
      okText: '确认',
      cancelText: '取消',
      centered:true,
      onOk(){
        http({
        url: "/data/HJJC_ALARM_HANDLE.aspx",
        data: {
            CKM: me.$store.state.loggedIn.CKM,
            AlarmID: me.AlarmID,
            Explain:'已确认'
        },
        success(obj) {
          me.onSearch();
        },
          failure(obj) {
          },
          me,
        });
      },
      onCanel(e){
        e.stopPropagation();
      }
    });
    },
    //处理报警信息确认
    handleAlarmOk(e){
      var me=this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          me.confirmLoading=true;
          http({
          url: "/data/HJJC_ALARM_HANDLE.aspx",
          data: {
              CKM: me.$store.state.loggedIn.CKM,
              AlarmID: me.AlarmID,
              Explain:me.form.Explain
          },
          success(obj) {
            me.alarmVisible=false;
            me.confirmLoading=false;
            me.AlarmID='';
            me.Explain='';
            me.onSearch();
          },
            failure(obj) {
              me.confirmLoading=false;
            },
            me,
          });
        } else {
          return false;
        }
      });
    },
    //处理报警信息取消
    handleAlarmCancel(){
      var me=this;
      me.alarmVisible=false;
    },
    //点击树结构折叠
    onClickExpand(iconType) {
      console.log(iconType);
      let me = this;
      if (iconType == "right") {
        me.echartWidth = "1144px";
      } else {
        me.echartWidth = "800px";
      }
      let title = "",
        KSSJ = "",
        JSSJ = "";
      switch (me.TJLX[0]) {
        case 1:
        case "1":
          title = "历史小时数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史小时实测数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 5:
        case "5":
          title = "历史分钟数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史分钟实测数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 2:
        case "2":
          title = "历史日数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史日实测数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM");
          JSSJ = dayjs(me.date2).format("YYYY-MM");
          break;
        case 4:
        case "4":
          title = "历史月数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史月实测数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY");
          JSSJ = dayjs(me.date2).format("YYYY");
          break;
      }
      let obj = {
        CKM: me.$store.state.loggedIn.CKM,
        station_bh: me.selectedData.id,
        // station_Type: me.selectedData.station_Type,
        TJLX: me.TJLX[0],
        KSSJ,
        JSSJ,
        // ItemCode: 99,
        EnterpriseBH: me.selectedData.parentId,
      };
      // console.log(obj);
      me.getAjax(obj); // 请求 echarts 的ajax
    },
    //选择时效性
    onTreeSelect(item) {
      console.log("树的选择信息：", item);
      let me = this;
      if (!item.leaf) {
        return;
      }
      me.selectedData = item;

      if (item.Lx != "1") {
        me.parentTitle = this.$store.state.parentData.name;
        me.title = this.$store.state.selectedData.name;
      }

      // var now = dayjs().format("YYYY-MM-DD");
      // var day = dayjs().add(1, "day").format("YYYY-MM-DD");
      // me.date1 = day;
      // me.date2 = now;

      me.onSearch();
    },
    //选择数据方式 实测和折算切换
    onDateType(){},

    onChange(value) {
      console.log(value);
      // if (value == 1) {
      //   //小时数据-显示天
      //   this.mode = ["date", "date"];
      //   this.dateFormat = "YYYY-MM-DD";
      // } else if (value == 2) {
      //   //日数据-显示月
      //   this.mode = ["month", "month"];
      //   this.dateFormat = "YYYY-MM";
      // } else if (value == 4) {
      //   //月数据-显示年
      //   this.mode = ["year", "year"];
      //   this.dateFormat = "YYYY";
      // }
    },

    /**
     * 单选按钮的change事件
     */
    onChamgeType(e) {
      // console.log("radio1 checked", e.target.value);
      let me = this;
      me.showType = e.target.value;
      if (me.showType == 2) {
        //图表
        me.zEchartsIndex = "2";
        me.zTableIndex = "1";
        me.isShowAlarmColor = false;
      } else {
        //列表
        me.zEchartsIndex = "1";
        me.zTableIndex = "2";
        me.isShowAlarmColor = true;
      }
      let title = "";
      let zsttile="";
      switch (me.TJLX[0]) {
        case 1:
        case "1":
          title = "历史小时数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史小时实测数据";
            zsttile="历史小时折算数据";
          }
          break;
        case 2:
        case "2":
          title = "历史日数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史日实测数据";
            zsttile="历史日折算数据";
          }
          break;
        case 4:
        case "4":
          title = "历史月数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史月实测数据";
            zsttile="历史月折算数据";
          }
          break;
        case 5:
        case "5":
          title = "历史分钟数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史分钟实测数据";
            zsttile="历史分钟折算数据";
          }
          break;
      }
      me.smallTitle = title; // 设置小标题
      me.smallzsTitle=zsttile;//设置折算小标题
    },
    /**
     * 日期选择框的change事件
     */
    onChooseDate(dates, dates2) {
      let me = this;
      me.date1 = dates2[0];
      me.date2 = dates2[1];
      console.log(me.date1, me.date2);
    },

    handlePanelChange2(value, mode){
      console.log(value);
      console.log(mode);
    },

    /**
     * 查询按钮的点击事件
     */
    onSearch() {
      let me = this;
      let title = "",
        KSSJ = "",
        JSSJ = "";
      let zsttile="";
      switch (me.TJLX[0]) {
        case 1:
        case "1":
          title = "历史小时数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史小时实测数据";
            zsttile="历史小时折算数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 2:
        case "2":
          title = "历史日数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史日实测数据";
            zsttile="历史日折算数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 4:
        case "4":
          title = "历史月数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史月实测数据";
            zsttile="历史月折算数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY");
          JSSJ = dayjs(me.date2).format("YYYY");
          break;
        case 5:
        case "5":
          title = "历史分钟数据";
          if(me.selectedData.station_Type==1&&me.showType == 2){
            title = "历史分钟实测数据";
            zsttile="历史分钟折算数据";
          }
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
      }

      me.smallTitle = title; // 设置小标题
      me.smallzsTitle=zsttile;//设置折算小标题

      let obj = {
        CKM: me.$store.state.loggedIn.CKM,
        station_bh: me.selectedData.id,
        // station_Type: me.selectedData.station_Type,
        TJLX: me.TJLX[0],
        KSSJ,
        JSSJ,
        // ItemCode: 99,
        EnterpriseBH: me.selectedData.parentId,
      };
      let objSdParams = {
        CKM: me.$store.state.loggedIn.CKM,
        SFBH: "",
        SBH: "",
        XBH: "",
        JZMC: "",
        station_bh: me.selectedData.id,
        SXZM: "",
        KSSJ,
        JSSJ,
        PAGESIZE: me.pageSizeSd,
        PAGENUMBER: me.currentSd,
      };
      // console.log(obj);
      me.getAjax(obj); // 请求 echarts 的ajax
      me.getAjaxTable(obj, objSdParams); // 请求 table 的ajax
    },

    /**
     * 导出数据的点击事件
     */
    onExport() {
      let me = this,
        KSSJ = "",
        JSSJ = "";
      switch (me.TJLX[0]) {
        case 1:
        case "1":
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 2:
        case "2":
          KSSJ = dayjs(me.date1).format("YYYY-MM");
          JSSJ = dayjs(me.date2).format("YYYY-MM");
          break;
        case 4:
        case "4":
          KSSJ = dayjs(me.date1).format("YYYY");
          JSSJ = dayjs(me.date2).format("YYYY");
          break;
        case 5:
        case "5":
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
      }

      let QueryCondition = `${me.$store.state.loggedIn.CKM},${me.$store.state.selectedKeys[0]},${me.TJLX[0]},${KSSJ},${JSSJ},${me.$store.state.expandedKeys[0]}`;
      console.log(QueryCondition);
      // console.log(me.$store.state.selectedKeys[0]);
      // console.log(me.TJLX[0]);
      // console.log(me.date1);
      // console.log(me.date2);
      // console.log(me.$store.state.expandedKeys[0]);

      // https://pc.allspark.top/data/RPT_GetPrintExcelFile_DynData.aspx?
      // Module=SP&UID=admin&PID=
      // &Template=104C4B0D23F6410B91A2764DBFHJJCCS
      // &QueryCondition=E3A8C5F2C1BD42DE966FE8E1E32D6286
      // %2C
      // station200113000001
      // %2C
      // 1
      // %2C
      // 2020-11-27
      // %2C
      // 2020-12-04
      // %2C
      // QY200113000001
      // console.log(QueryCondition);
      // return

      let HTTPURL = this.$store.state.HTTPURL;
      console.log(HTTPURL);

      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile_DynData.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2764DBFHJJCCS&QueryCondition=" +
          QueryCondition +
          ""
      );
    },

    /**
     * 分页切换点击事件
     */
    onPageChange(page, pageSize) {
      let me = this;
      // console.log(112313232);
      me.start = page || 1;
      me.limit = pageSize;
      // console.log("me.start, me.limit::", me.start, me.limit);
      me.onSearch();
    },

    /**
     * 分页的数据change事件
     */
    onShowSizeChange(current, pageSize) {
      // console.log("current, pageSize::", current, pageSize);
      let me = this;
      me.start = current || 1;
      me.limit = pageSize;
      me.onSearch();
    },

    /**
     * 请求数据的接口
     * params:所有的入参
     */
    getAjax(params) {
      let me = this;
      // console.log(params);
      params.station_Type = this.$store.state.selectedData.station_Type;
      params.ItemCode = 99;
      var ACTUAL_UNIT;
      var charttype = "line",
        chartdate = dayjs().format("YYYY-MM-DD");
      // console.log("chartdate:",chartdate);
      http({
        url: "/data/HJJC_CHART_LINE_Ext.aspx",
        data: params,
        success(obj) {
          console.log("echarts 图表数据：", obj);

          var day_xAxis = [];
          var day_col = [];

          var month_xAxis = [];
          var month_col = [];

          var year_xAxis = [];
          var year_col = [];
          var minute_xAxis = [];
          var minute_col = [];
          var data5=[];
          if(obj.Data5 && obj.Data5.length > 0){
            data5=obj.Data5;
          }
          if (obj.Data && obj.Data.length > 0) {
            // console.log("开始for循环")
            ACTUAL_UNIT = obj.Data[0].ACTUAL_UNIT;
            for (let i = 0; i < obj.Data.length; i++) {
              day_col[i] = obj.Data[i]; //天的数据
              day_col[i].cols = [];
              month_col[i] = obj.Data[i]; //月的数据
              month_col[i].cols = [];
              year_col[i] = obj.Data[i]; //年的数据
              year_col[i].cols = [];
              minute_col[i] = obj.Data[i]; //年的数据
              minute_col[i].cols = [];
              let JCX=0;
              let PFBZ=0;
              for(let j=0;j<data5.length;j++){
                if(obj.Data[i].ItemCode==data5[j].ItemCode){
                  JCX=data5[j].JCX;
                  PFBZ=data5[j].PFBZ;
                }
              }
              day_col[i].JCX = JCX;
              day_col[i].PFBZ = PFBZ;
              month_col[i].JCX = JCX;
              month_col[i].PFBZ = PFBZ;
              year_col[i].JCX = JCX;
              year_col[i].PFBZ = PFBZ;
              minute_col[i].JCX=JCX;
              minute_col[i].PFBZ=PFBZ;
            }
          }
          if (!obj.Data1) obj.Data1 = []; //小时
          if (!obj.Data2) obj.Data2 = []; //天
          if (!obj.Data3) obj.Data3 = []; //月
          if (!obj.Data4) obj.Data4 = []; //分钟

          obj.Data1.forEach(function (item, index) {
            //20201223 开始注释
            // if (item.HOUR.length == 10) {
            //   day_xAxis.push(item.HOUR.substring(0, 2));
            // } else {
            //   if (index == 0) {
            //     day_xAxis.push(
            //       item.RQ.substring(5, 10) + " " + item.HOUR.substring(0, 2)
            //     );
            //   } else {
            //     if (item.HOUR.substring(0, 2) == "00" && item.RQ) {
            //       day_xAxis.push(item.RQ.substring(5, 10));
            //     } else day_xAxis.push(item.HOUR.substring(0, 2));
            //   }
            // }
            //20201223 结束注释
            day_xAxis.push(item.RQ + " " + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = day_col[i].MC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              day_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>" + item.RQ + "",
                },
              });
            }
          });
          obj.Data2.forEach(function (item, index) {
            if (item.HOUR == "1") month_xAxis.push(item.RQ.substring(0, 8));
            else month_xAxis.push(item.RQ + "-" + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = month_col[i].MC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              month_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>{b}",
                },
              });
            }
          });
          obj.Data3.forEach(function (item, index) {
            year_xAxis.push(item.RQ + "-" + item.HOUR);
            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = year_col[i].MC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              year_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>{b}",
                },
              });
            }
          });
          obj.Data4.forEach(function (item, index) {
            minute_xAxis.push(item.RQ + " " + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = day_col[i].MC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              minute_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>" + item.RQ + "",
                },
              });
            }
          });

          var day_series = [];
          var month_series = [];
          var year_series = [];
          var minute_series = [];
          //增加图例选项
          for (var i = 0; i < obj.Data.length; i++) {
            let colortop = me.color[i % 9],
              colorbottom = me.color1[i % 9];
            day_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: day_col[i].ItemName,
              data: day_col[i].cols,
              JCX:day_col[i].JCX,
              PFBZ:day_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            month_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: month_col[i].ItemName,
              data: month_col[i].cols,
              JCX:month_col[i].JCX,
              PFBZ:month_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            year_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: year_col[i].ItemName,
              data: year_col[i].cols,
              JCX:year_col[i].JCX,
              PFBZ:year_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            minute_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: minute_col[i].ItemName,
              data: minute_col[i].cols,
              JCX:minute_col[i].JCX,
              PFBZ:minute_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
          }
          var data = {
            dataDay: {
              xAxis: day_xAxis,
              series: day_series,
            },
            dataMonth: {
              xAxis: month_xAxis,
              series: month_series,
            },
            dataYear: {
              xAxis: year_xAxis,
              series: year_series,
            },
            dataMinute: {
              xAxis: minute_xAxis,
              series: minute_series,
            },
          };
          console.log(data);
          let myChart = document.getElementById("EchartsHistory");
          let SJLX = me.TJLX[0];
          me.loadChartData(
            myChart,
            SJLX,
            params.station_Type,
            data,
            99,
            ACTUAL_UNIT
          );
          //这个条件是当前显示为图表并且为气基站的时候 加载折算图表&&me.showType == 2
          if(me.selectedData.station_Type==1){
            me.loadZSData(obj,params);
          }
        },
        failure(obj) {},
      });
    },
    /**
     * 加载折算数据  这个条件是当前显示为图表并且为气基站的时候
     */
    loadZSData(obj,params){
      console.log(obj);
      console.log(params);
      var me=this;
      var ACTUAL_UNIT;
      var charttype = "line";
      var day_xAxis = [];
          var day_col = [];

          var month_xAxis = [];
          var month_col = [];

          var year_xAxis = [];
          var year_col = [];
          var minute_xAxis = [];
          var minute_col = [];
          var data5=[];
          if(obj.Data5 && obj.Data5.length > 0){
            data5=obj.Data5;
          }
          if (obj.Data && obj.Data.length > 0) {
            // console.log("开始for循环")
            ACTUAL_UNIT = obj.Data[0].ACTUAL_UNIT;
            for (let i = 0; i < obj.Data.length; i++) {
              day_col[i] = obj.Data[i]; //天的数据
              day_col[i].cols = [];
              month_col[i] = obj.Data[i]; //月的数据
              month_col[i].cols = [];
              year_col[i] = obj.Data[i]; //年的数据
              year_col[i].cols = [];
              minute_col[i] = obj.Data[i]; //分钟的数据
              minute_col[i].cols = [];
              let JCX=0;
              let PFBZ=0;
              for(let j=0;j<data5.length;j++){
                if(obj.Data[i].ItemCode==data5[j].ItemCode){
                  JCX=data5[j].JCX;
                  PFBZ=data5[j].PFBZ;
                }
              }
              day_col[i].JCX = JCX;
              day_col[i].PFBZ = PFBZ;
              month_col[i].JCX = JCX;
              month_col[i].PFBZ = PFBZ;
              year_col[i].JCX = JCX;
              year_col[i].PFBZ = PFBZ;
              minute_col[i].JCX=JCX;
              minute_col[i].PFBZ=PFBZ;
            }
          }
          if (!obj.Data1) obj.Data1 = []; //小时
          if (!obj.Data2) obj.Data2 = []; //天
          if (!obj.Data3) obj.Data3 = []; //月
          if (!obj.Data4) obj.Data4 = []; //分钟

          obj.Data1.forEach(function (item, index) {
            //20201223 开始注释
            // if (item.HOUR.length == 10) {
            //   day_xAxis.push(item.HOUR.substring(0, 2));
            // } else {
            //   if (index == 0) {
            //     day_xAxis.push(
            //       item.RQ.substring(5, 10) + " " + item.HOUR.substring(0, 2)
            //     );
            //   } else {
            //     if (item.HOUR.substring(0, 2) == "00" && item.RQ) {
            //       day_xAxis.push(item.RQ.substring(5, 10));
            //     } else day_xAxis.push(item.HOUR.substring(0, 2));
            //   }
            // }
            //20201223 结束注释
            day_xAxis.push(item.RQ + " " + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = day_col[i].ZSMC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              day_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>" + item.RQ + "",
                },
              });
            }
          });
          obj.Data2.forEach(function (item, index) {
            if (item.HOUR == "1") month_xAxis.push(item.RQ.substring(0, 8));
            else month_xAxis.push(item.RQ + "-" + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = month_col[i].ZSMC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              month_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>{b}",
                },
              });
            }
          });
          obj.Data3.forEach(function (item, index) {
            year_xAxis.push(item.RQ + "-" + item.HOUR);
            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = year_col[i].ZSMC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              year_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>{b}",
                },
              });
            }
          });

          obj.Data4.forEach(function (item, index) {
            minute_xAxis.push(item.RQ + " " + item.HOUR);

            for (var i = 0; i < obj.Data.length; i++) {
              var MCS = minute_col[i].ZSMC;
              let val = eval("item." + MCS); //eval 将字符串当做语句运行
              if (val == null || val == undefined) {
                val = "";
              }
              minute_col[i].cols.push({
                value: val,
                ACTUAL_UNIT: obj.Data[i].ACTUAL_UNIT,
                tooltip: {
                  formatter: "{c}<br>" + item.RQ + "",
                },
              });
            }
          });

          var day_series = [];
          var month_series = [];
          var year_series = [];
          var minute_series = [];
          //增加图例选项
          for (var i = 0; i < obj.Data.length; i++) {
            let colortop = me.color[i % 9],
              colorbottom = me.color1[i % 9];
            day_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: day_col[i].ItemName,
              data: day_col[i].cols,
              JCX:day_col[i].JCX,
              PFBZ:day_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            month_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: month_col[i].ItemName,
              data: month_col[i].cols,
              JCX:month_col[i].JCX,
              PFBZ:month_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            year_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: year_col[i].ItemName,
              data: year_col[i].cols,
              JCX:year_col[i].JCX,
              PFBZ:year_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
            minute_series.push({
              smooth: true,
              type: charttype,
              ItemCode: obj.Data[i].ItemCode,
              name: minute_col[i].ItemName,
              data: minute_col[i].cols,
              JCX:minute_col[i].JCX,
              PFBZ:minute_col[i].PFBZ,
              // symbolSize: 8,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colortop, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorbottom, // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
          }
          var data = {
            dataDay: {
              xAxis: day_xAxis,
              series: day_series,
            },
            dataMonth: {
              xAxis: month_xAxis,
              series: month_series,
            },
            dataYear: {
              xAxis: year_xAxis,
              series: year_series,
            },
            dataMinute: {
              xAxis: minute_xAxis,
              series: minute_series,
            }
          };
          let myChart = document.getElementById("EchartsHistoryZS");
          let SJLX = me.TJLX[0];
          me.loadChartData(
            myChart,
            SJLX,
            params.station_Type,
            data,
            99,
            ACTUAL_UNIT
          );
    },
    /**
     * 加载 echarts 数据
     * dom：dom元素
     * itemId：数据类型：1：小时数据。2：日数据。。4：月数据。
     * stationType：基站类型:0全部，1废气2废水
     * data:全部数据
     * itemCord：99  直接传的99，不知道啥意思！！！！！！！！！！！！！！！！！！！！！！！！！！
     * ACTUAL_UNIT：y轴的单位。
     */
    loadChartData(dom, itemId, stationType, data, itemCord, ACTUAL_UNIT) {
      let me = this;

      // console.log(data);
      // var myChart = echarts.init(dom);
      var echarts = require("echarts");
      let myChart =echarts.getInstanceByDom(dom) || echarts.init(dom);
      // // 获取自适应的高度和宽度
      // var resizeMyChartContainer = function () {
      //   myChart.style.width = me.echartWidth;
      // };
      // // 设置容器高和宽
      // resizeMyChartContainer();
      myChart.clear();
      //myChart.dispose();

      var option = {};
      var stationDW = "";
      if (itemCord == "99") {
        stationDW = "";
      } else {
        stationDW = "实测" + ACTUAL_UNIT;
      }
      var yAxisName = stationDW; // '实测mg/m³' : '实测mg/L';

      var titleName = null;
      // if (itemId == 1) {
      //     titleName = "小时数据曲线图";
      // }
      // if (itemId == 2) {
      //     // 日
      //     titleName = "日数据曲线图";
      // }
      // if (itemId == 4) {
      //     //月
      //     titleName = "月数据曲线图";
      // }

      // console.log("itemId:", itemId);

      switch (itemId) {
        case 1:
        case "1":
          if (data.dataDay.series && data.dataDay.series.length > 0) {
            option = me.createChartOption({
              title: titleName,
              xAxis: {
                name: "小时",
                // axisLabel: {
                // interval: 4,
                // rotate: 35
                // },
                boundaryGap: false,
                data: data.dataDay.xAxis,
              },
              yAxis: {
                name: yAxisName,
              },

              series: data.dataDay.series,
            });
            // console.log("option:", option);
          }
          break;
        case 2:
        case "2":
          if (data.dataMonth.series && data.dataMonth.series.length > 0) {
            // console.log(data.dataMonth.xAxis);
            option = me.createChartOption({
              title: titleName,
              xAxis: {
                name: "日",
                // axisLabel: {
                // interval: 4,
                // rotate: 35
                // },
                boundaryGap: false,
                data: data.dataMonth.xAxis,
              },
              yAxis: {
                name: yAxisName,
              },

              series: data.dataMonth.series,
            });
          }
          break;
        case 4:
        case "4":
          if (data.dataYear.series && data.dataYear.series.length > 0) {
            option = me.createChartOption({
              title: titleName,
              xAxis: {
                name: "月份",
                // axisLabel: {
                // interval: 4,
                // rotate: 35
                // },
                boundaryGap: false,
                data: data.dataYear.xAxis,
              },
              yAxis: {
                name: yAxisName,
              },

              series: data.dataYear.series,
            });
          }
          break;
        case 5:
        case "5":
          if (data.dataMinute.series && data.dataMinute.series.length > 0) {
            option = me.createChartOption({
              title: titleName,
              xAxis: {
                name: "分钟",
                // axisLabel: {
                // interval: 4,
                // rotate: 35
                // },
                boundaryGap: false,
                data: data.dataMinute.xAxis,
              },
              yAxis: {
                name: yAxisName,
              },

              series: data.dataMinute.series,
            });
            // console.log("option:", option);
          }
          break;
      }
      myChart.setOption(option, true);
      // if(Number(stationType)==1){
      //   //表示为气 
      //   console.log('当前基站类型为气');
      //   me.dealStationAir(option,myChart,titleName,yAxisName,data);
      // }
      me.dealStationAir(option,myChart,titleName,yAxisName,data);
      // 自适应高和宽
      myChart.resize({
        width: me.echartWidth,
      });
    },
    /**
     * 处理基站类型为气的时候
     * option 当前的option
     * myChart 当前myChart对象。
     * titleName 标题
     * yAxisName y轴的名字
     * data 数据库组织好的数据
     */
    dealStationAir(option,myChart,titleName,yAxisName,data){
      let selectArr=option.legend.data;
      myChart.on('legendselectchanged',function(obj){
        let selected=obj.selected;
        let name=obj.name;
        let count=0;
        for(let key in selected){
          if(selected[key]){
            count++;
          }
        }
        option.xAxis.nameTextStyle = {};
        if (option.xAxis.name.indexOf('(') > -1) {
            option.xAxis.name=option.xAxis.name.replace('\n\n','');
            var index = option.xAxis.name.indexOf('(');
            option.xAxis.name = option.xAxis.name.substring(0,index);
        }
        for(let i=0;i<option.series.length;i++){
          option.series[i].markLine={};
        }
        if(count==1){
           let currentSelect='';
           for(let key in selected){
              if(selected[key]){
                currentSelect=key;
              }
           }
           for(let i=0;i<option.series.length;i++){
             if(option.series[i].name==currentSelect){
               option.series[i].markLine={
                 slient:false,
                 //symbol:'none',//去掉箭头
                 data:[
                   {
                    yAxis:option.series[i].JCX,
                     lineStyle:{
                       type:'dashed',
                       color:'red'
                     },
                     label:{
                        show:true,
                        color:'red',
                        position:'end',
                        fontSize:16,
                        formatter:function(params){
                          //return params.name+': '+params.value
                          return params.value
                        }
                      },
                     //name:'检出限'
                   },
                   {
                    yAxis:option.series[i].PFBZ,
                     lineStyle:{
                       type:'dashed',
                       color:'green'
                     },
                     label:{
                        show:true,
                        color:'green',
                        position:'end',
                        fontSize:16,
                        formatter:function(params){
                          //return params.name+': '+params.value
                          return params.value
                        }
                      },
                     //name:'排放标准'
                   }
                 ]
               };
               if(option.series[i].PFBZ==option.series[i].JCX){
                  option.series[i].markLine={
                  slient:false,
                  //symbol:'none',//去掉箭头
                  lineStyle:{
                    type:'dashed',
                    color:'red'
                  },
                  label:{
                    show:true,
                    color:'red',
                    position:'end',
                    fontSize:16,
                    formatter:function(params){
                      // return params.name+': '+params.value
                      return params.value;
                    }
                  },
                  data:[
                    {
                      yAxis:option.series[i].JCX,
                      lineStyle:{
                        type:'dashed'
                      },
                      //name:'检出限(排放标准)'
                    }
                  ]
                };
               }
             }
           }
           option.xAxis.name = option.xAxis.name + '\n\n({red|排放标准:红色}/{green|检出限:绿色} 注意:两者相等重合)';
           option.xAxis.nameTextStyle = {
                rich: {
                    red: {
                        color: 'red'
                    },
                    green: {
                        color: 'green'
                    }
                }
           };
        }
        option.legend.selected=selected;
        myChart.setOption(option, true);
      })
    },
    createChartOption(config) {
      console.log(config)
      let me = this;
      let legends = [];
      let lengendSelected = {};
      for (var i = 0; i < config.series.length; i++) {
        legends.push(config.series[i].name);
        if (config.series[i].ItemCode == "a00000") {
          lengendSelected["烟气流量"] = false;
        }
        if (config.series[i].ItemCode == "w00000") {
          lengendSelected["水流量"] = false;
        }
      }
      var legend = {};
      let axisLabel = {
        interval: 4,
        // rotate: 35
      };
      if (me.TJLX[0] == "1") {
        axisLabel = {
          interval: 4,
          // rotate: 35,
          formatter: function (params, ticket, callback) {
            let temp = "";
            if (params.length == 13) {
              let time = params;
              temp = params.split(" ")[1];
              if (temp == "00") {
                temp = time.substring(5, 10);
              }
            }
            return temp;
          },
        };
      }
      return {
        backgroundColor: "#fff",
        color: me.color,
        title: {
          text: config.title,
          left: "center",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params, ticket, callback) {
            let htmlStr = "";
            for (let i = 0; i < params.length; i++) {
              let param = params[i];
              let xName = param.name; //x轴的名称
              let seriesName = param.seriesName; //图例名称
              let value = param.value; //y轴值
              let color = param.color; //图例颜色
              let unit = param.data.ACTUAL_UNIT;
              if (i === 0) {
                htmlStr += xName + "<br/>"; //x轴的名称
              }
              htmlStr += "<div>";

              // 具体显示的数据【字段名称：seriesName，值：value】
              // 这里判断一下name，如果是我们需要特殊处理的，就处理
              // 正常显示的数据，走默认
              htmlStr +=
                '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                color +
                ';"></span>';
              let unitHtml = "";
              if (unit == "" || unit == null) {
              } else {
                unitHtml = "(" + unit + ")";
              }
              htmlStr += seriesName + "：" + value + unitHtml;
            }
            return htmlStr;
          },
        },
        legend: {
          top: 20,
          right: "2%",
          itemWidth: 10,
          itemHeight: 8,
          data: legends,
          textStyle: {
            fontSize: 14,
          },
          selected: lengendSelected,
        },
        grid: {
          top: 70,
          bottom: 65,
          left: "3%",
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // axisLabel: axisLabel,
          name: config.xAxis.name,
          nameLocation: "center",
          nameGap: 25,
          boundaryGap: true,
          nameTextStyle: {
            fontSize: 14,
          },
          data: config.xAxis.data,
          itemStyle: {
            normal: {
              label: {
                show: true, //是否展示
                position: "top",
                textStyle: {
                  color: "#000",
                },
              },
              shadowBlur: 80,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            normal: {
              show: true,
              position: "top",
            },
          },
        },
        yAxis: {
          type: "value",
          name: config.yAxis.name,
          nameTextStyle: {
            fontSize: 10,
          },
          splitArea: {
            show: false,
          },
          axisPointer: {
            snap: true,
          },
        },
        dataZoom: [
          {
            type: "inside",
            throttle: "0",
            startValue: 0,
            endValue: config.xAxis.data.length,
          },
        ],

        series: config.series,
      };
    },

    /**
     * 请求 table 的 ajax
     */
    getAjaxTable(obj, objSdParams) {
      let me = this;
      let params = {
        ...obj,
        // start: me.start,
        // limit: me.limit,
      };
      me.loading = true;
      me.currentStationIsTingYun = false;
      http({
        url: "/data/HJJC_DATA_REPORT_HIS.aspx",
        data: params,
        success(obj) {
          // console.log("table:",obj);
          me.loading = false;
          if (obj.Data3 && obj.Data3.length > 0) {
            //obj.Data3[0].TS = 1;
            console.log("进入是否停运");
            if (obj.Data3[0].TS == 1) {
              me.currentStationIsTingYun = true; //设为true显示状态 停运 否则不显示
              //获取停运时段
              me.getStopTime(objSdParams);
            } else {
              me.currentStationIsTingYun = false; //设为true显示状态 停运 否则不显示
            }
          }
          me.slotcolumns = obj.Data1;
          // console.log(me.slotcolumns);

          let tree = me.formatColumns(obj.Data, obj.Data1);
          console.log(tree);
          me.columns = tree;

          me.data = me.formatData(obj); // 格式化data数据
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },
    //分页
    onChangeSdPage(pageNumber) {
      let me = this;
      me.currentSd = pageNumber;
      let title = "",
        KSSJ = "",
        JSSJ = "";
      switch (me.TJLX[0]) {
        case 1:
        case "1":
          title = "历史小时数据";
          KSSJ = dayjs(me.date1).format("YYYY-MM-DD");
          JSSJ = dayjs(me.date2).format("YYYY-MM-DD");
          break;
        case 2:
        case "2":
          title = "历史日数据";
          KSSJ = dayjs(me.date1).format("YYYY-MM");
          JSSJ = dayjs(me.date2).format("YYYY-MM");
          break;
        case 4:
        case "4":
          title = "历史月数据";
          KSSJ = dayjs(me.date1).format("YYYY");
          JSSJ = dayjs(me.date2).format("YYYY");
          break;
      }
      let objSdParams = {
        CKM: me.$store.state.loggedIn.CKM,
        SFBH: "",
        SBH: "",
        XBH: "",
        JZMC: "",
        station_bh: me.selectedData.id,
        SXZM: "",
        KSSJ,
        JSSJ,
        PAGESIZE: me.pageSizeSd,
        PAGENUMBER: me.currentSd,
      };
      this.getStopTime(objSdParams);
    },

    //获取停运时段
    getStopTime(objSdParams) {
      let me = this;
      let params = {
        ...objSdParams,
      };
      me.loadingSd = true;
      http({
        url: "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx",
        data: params,
        success(obj) {
          console.log("table:", obj);
          me.loadingSd = false;
          if (obj.Data && Array.isArray(obj.Data)) {
            me.dataSd = obj.Data;
          }
          if (obj.Data1 && obj.Data1.length > 0) {
            me.totalSd = obj.Data1[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loadingSd = false;
        },
        me,
      });
    },

    formatColumns(parentAry, childrenAry) {
      console.log(parentAry);
      console.log(childrenAry);
      var tree = parentAry.map((p) => {
        p.title = p.SC;
        p.align = "center";

        p.children = childrenAry.filter((c) => {
          // console.log(c);
          if (p.ItemCode == c.ItemCode) {
            if (c.ACTUAL_UNIT == "" || c.ACTUAL_UNIT == null) {
              c.title = c.ZT;
            } else {
              c.title = c.ZT + "(" + c.ACTUAL_UNIT + ")";
            }

            c.width = 128;
            c.dataIndex = c.MC;
            c.key = c.MC;
            c.align = "center";
            //测试弹出框暂时注释 20220118
            c.scopedSlots = { customRender: "filterfff" };
            //c.defaultSortOrder='descend';
            //排序-有问题暂时隐藏
            // c.sorter=(a, b)=>parseInt(a[c.MC].split('|||')[0]) - parseInt(b[c.MC].split('|||')[0]);
            return c;
          }
        });
        return p;
      });
      // console.timeEnd();
      if(this.TJLX[0]==5){
        tree.unshift({
          title: "时间",
          dataIndex: "HOUR",
          width: 160,
          align: "center",
        });
      }else{
        tree.unshift({
        title: "时间",
        dataIndex: "HOUR",
        width: 128,
        align: "center",
        });
      }
      
      return tree;
    },

    /**
     * 格式化 data 数据
     * obj：全部的ajax返回的数据
     */
    formatData(obj) {
      let me = this;
      // Data2:详细信息。Data3：平均/最大/最小/样本。Data4：排放总量。
      let { Data2 } = obj;
      // console.log(Data2);

      let newData2 = me.getNewObj(Data2);

      // let data = [...Data2, ...Data3, d4];
      // console.log(newData2);
      return newData2;
    },
    getNewObj(list) {
      //console.log(list)
      let me = this;
      let newList = [];
      list.forEach((item) => {
        let newObj = {};
        for (let i in item) {
          // 先判断是否“ZT”结尾
          if (i == "HOUR") {
            // TJLX: ["1"], // 数据类型：1：小时数据。2：日数据。。4：月数据
            let tjlx = me.TJLX[0];
            if (tjlx == 1 || tjlx == "1") {
              newObj[i] = item["RQ"] + " " + item[i];
            } else if (tjlx == 4 || tjlx == "4") {
              newObj[i] = item["YEAR"] + "-" + item[i];
              if(item[i].toString().length==1){//月份只有一位前面补0
                newObj[i] = item["YEAR"] + "-0" + item[i];
              }
            } else {
              newObj[i] = item[i];
            }
          } else if (i.indexOf("ZT") == -1) {
            // console.log((typeof item[i+"ZT"]) == "undefined");
            newObj[i] = (item[i] || "") + "|||" + item[i + "ZT"];
            let temp=i;
            let str=temp.split('_');
            if(item.hasOwnProperty(''+str[0]+'_AlarmState')){
               if(str[1]=='SC'){//表示实测 是实测的时候要判断一下是否含有折算  有折算的时候  有报警信息显示到折算里面 否侧就显示到实测里面
                  if(item.hasOwnProperty(''+str[0]+'_ZS')){//手动添加后缀方便显示报警信息显示
                    newObj[i] +="|||"+item[str[0] + "_AlarmState"]+"|||" + item[str[0] + "_AlarmID"]+"|||"+str[1];
                  }else{
                    newObj[i] +="|||"+item[str[0] + "_AlarmState"]+"|||" + item[str[0] + "_AlarmID"]+"|||"+"ZS";
                  }
               }else{
                 newObj[i] +="|||"+item[str[0] + "_AlarmState"]+"|||" + item[str[0] + "_AlarmID"]+"|||"+str[1];
               }
            }
          }
        }
        //console.log(newObj);
        newList.push(newObj);
      });
      // console.log(newList);
      return newList;
    },

    /**
     * 格式化table的数据展示。
     */
    formatTable(text,record) {
      let me = this;
      // console.log(text);
      if (typeof text == "string") {
        let t = text.split("|||")[0],
          start = text.split("|||")[1];
        // console.log(t);

        if (start == 1 || start == "1") {
          return `<span style="color:#884898; font-weight:700;">${t}</span>`;
        } //异常
        if (start == 2) {
          return `<span style="color:#e22222; font-weight:700;">${t}</span>`;
        } //超标
        if (start == 3) {
          return `<span style="color:#f67c49; font-weight:700;">${t}</span>`;
        } //预警
        return `<span >${t}</span>`;
      } else {
        return `<span >${text.split("|||")[0]}</span>`;
      }
    },

    /**
     * 列表转换成树结构
     */
    list2tree(data) {
      var arr = [...data];

      let thisParentId = []; // 每循环一项，把当前的父级id给存放在这里，后面进行去重。

      var tree = arr.map((v) => {
        if (thisParentId.indexOf(v.ItemCode) > -1) {
          return;
        } // 存在就break掉。
        // 查找二级
        v.title = v.ItemName;
        v.children = arr.filter(({ ItemCode }) => {
          return ItemCode == v.MC.split("_")[0];
        });
        thisParentId.push(v.ItemCode);
        if (v.children.length == 0) delete v.children;

        return v;
      });
      tree.forEach((item) => {
        tree.splice(
          tree.findIndex((item) => item === undefined),
          1
        );
      });
      return tree;
    },
  },
  mounted() {
    this.LX=this.$store.state.loggedIn.LX;
    console.log('当前企业类型');
    console.log(this.LX);
  },

  destoryed() {
    this.loading = false;
  },
};
</script>
<style lang="scss" >
.statusMenu {
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px !important;
  }
}
.ant-popover-inner{
  min-width: 0px;
  max-width: 420px;
}
.ant-popover-inner-content{
  padding:0px;
}
.myalarmmodel{
  .ant-modal-title{
    text-align: center;
  }
  .ant-modal-body{
    padding-bottom: 0;
  }
  .dealinfo{
    display: flex;
  }
  .ant-form-item{
    display: flex;
    margin-top:5px;
  }
  .ant-form-item-label{
    line-height: 0;
  }
  .ant-form-item-label > label{
    font-size: 12px;
    color:rgba(0, 0, 0, 0.65) !important;
  }
}
.ant-popover{
  z-index: 998 !important;
}
</style>
<style lang="scss" scoped>
#history-data {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  height: 100%;
  .statusMenu {
    .ant-dropdown-menu:hover,
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background: #fff;
    }
    .ant-dropdown-menu-item-active {
      background: #fff;
    }
  }
  .tree {
    width: 248px;
    height: 100%;
    padding: 20px 10px 0 0;
    margin: 0 10px 0 0;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;

    #tree {
      overflow: hidden;
    }
  }

  .tree::-webkit-scrollbar {
    width: 5px;
    background-color: #d9d9d9;
  }

  .tree::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #52c41a;
  }

  .content {
    background-color: #ffffff;
    padding: 20px 20px 0px 20px;
    flex: 1;
    
    overflow: hidden;
    // 顶部的title
    .title-wrapper {
      padding: 0px 0px 12px 0px;
      color: #181818;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background-color: #fff;
    }
    .area-wrapper {
      display: flex;
      .area {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        margin: 0px 10px 0 20px;
      }
    }
    .istingyun {
      margin-left: 20px;
    }
    // table的区域
    .table-wrapper {
      overflow: hidden;
      width: 100%;
      min-height: 792px;
      padding-bottom: 20px;
      //height: calc(100vh - 380px);
    }
    // table的分页区域
    .pagination-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    // echarts 区域
    .echart {
      flex: 1;
      height: calc(792px - 200px);
    }
  }
}
.deal-time{
  display:flex;margin-top: 10px;
}
.alarm{
  margin-left: 18px;
  cursor: pointer;
}
#tree{
  // max-height: 760px;
}
.alarmdeal{
    margin-top: 10px;
    cursor: pointer;
    background: #1890ff;
    color: #fff;
    width: 80px;
    height: 28px;
    border-radius: 4px;
    text-align: center;
    line-height: 28px;
}
</style>
